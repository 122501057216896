<template>
  <div class="yuangong_koubei_info" v-loading="loading">
    <YGKBCheck v-show="show" :kind={val} :stus="stuItems" @close="closeAction1" />
    <el-table
      v-if="!show"
      :data="datas"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      border
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="bm" label="部门" sortable width="180">
      </el-table-column>
      <el-table-column prop="tea" label="录入人" sortable width="180">
      </el-table-column>
      <el-table-column label="录入人数" sortable width="180">
        <template slot-scope="scope">
          <el-link @click="checkInfo(scope.row.all, 0)">{{
            scope.row.total
          }}</el-link>
        </template>
      </el-table-column>
      <!-- <el-table-column label="报名人数" sortable width="180">
        <template slot-scope="scope">
          <el-link @click="checkInfo(scope.row.baomings)">{{
            scope.row.baoming
          }}</el-link>
        </template>
      </el-table-column> -->
      <el-table-column label="结清人数" sortable width="180">
        <template slot-scope="scope">
          <el-link @click="checkInfo(scope.row.jieqings, 1)">{{
            scope.row.jieqing
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="结清占比" sortable width="180">
        <template slot-scope="scope">
            <div>{{scope.row.jieqingbi ? scope.row.jieqingbi+"%":null}}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import YGKBCheck from "./YGKBCheck.vue";
export default {
  name: "YGKBInfoLive",
  data() {
    return {
      datas: [],
      loading: false,
      show: false,
      stuItems: [],
      val:0
    };
  },
  props: ["stus", "year"],
  created() {
    this.loading = true;
    this.fenxi();
  },
  components: {
    YGKBCheck,
  },
  methods: {
    closeAction() {
      this.$router.go(-1);
    },
    async fenxi() {
      let bumen = [];
      let teas = [];
      for (let i in this.stus) {
        let item = this.stus[i];
        bumen.push(item.lururenBumen);
        teas.push(item.lururen);
      }
      bumen = [...new Set(bumen)];
      teas = [...new Set(teas)];
      let datas = [];
      for (let i in bumen) {
        this.$datas.meta = { kind: 0, info: bumen[i], year:this.year };
        let res = await this.$datas.koubeiFilter;
        datas.push({
          id: i,
          bm: bumen[i],
          total: res.data.count,
          baoming: "",
          jieqing: "",
          tea: "",
          children: [],
          baomings: [],
          jieqings: [],
          all: res.data.all,
        });
      }
      //获取所有的口碑老师
      let data_teas = [];
      for (let i in teas) {
        this.$datas.meta = { kind: 1, info: teas[i], year:this.year};
        let res = await this.$datas.koubeiFilter;
        data_teas.push({
          id: i,
          bm: res.data.bm,
          total: res.data.total,
          baoming: res.data.baoming,
          jieqing: res.data.jieqing,
          tea: teas[i],
          baomings: res.data.baomings,
          jieqings: res.data.jieqings,
          all: res.data.all,
        });
      }
      //组合数据
      for (let i in data_teas) {
        for (let j in datas) {
          if (data_teas[i].bm == datas[j].bm) {
            datas[j].children.push({
              ...data_teas[i],
              id: datas[j].id + "-" + data_teas[i].id
            });
            break;
          }
        }
      }
      let totleJieqing = 0; //存储总结请数
      datas = datas.map(item=>{
          let jieqing = 0;
          for(let i in item.children){
             jieqing += item.children[i].jieqing;
          }

          totleJieqing += jieqing;
          return {...item, jieqing};
      });
      datas = datas.map(item=>{
         return {...item, jieqingbi: ((item.jieqing/totleJieqing)*100).toFixed(2)};
      });
      //降序排序
      datas.sort((item1, item2) => item2.total - item1.total);
      for (let i in datas) {
        datas[i].children.sort((item1, item2) => item2.jieqing - item1.jieqing);
      }
      console.log('===================');
      console.log(datas);
      this.datas = datas;
      this.loading = false;
    },
    checkInfo(item, kind) {
      console.log(item);
      this.stuItems = item;
      this.show = true;
      this.kind = kind;
      //this.$router.push({ name: "YGKBCheck", query: { row: item } });
    },
    closeAction1() {
      this.show = false;
    },
  },
};
</script>
