<template>
  <div class="bj_koubei" v-loading="loading">
    <el-table :data="koubeis" 
        border 
        style="width: 100%"
        row-key="id"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column prop="label" label="年份" width="150">
      </el-table-column>
      <el-table-column prop="className" label="班级" width="100">
      </el-table-column>
      <el-table-column prop="teacherList" label="讲师"> </el-table-column>
      <el-table-column prop="zhigui" label="职规老师"> </el-table-column>
      <el-table-column prop="kaibanDate" label="开班时间"> </el-table-column>
      <el-table-column prop="biyeDate" label="毕业时间"> </el-table-column>
      <el-table-column prop="studentCount" label="毕业人数"> </el-table-column>
      <el-table-column label="口碑人数">
        <template slot-scope="scope">
          <el-link @click="checkInfo(scope.row)">{{ scope.row.num }}</el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "BJKouBeiLive",
  data() {
    return {
      koubeis: [],
      classes: [],
      loading: false,
    };
  },
  props:["year"],
  async created() {
    this.loading = true;
    this.$datas.meta = { kind: 1, date:this.year};
    let res = await this.$datas.getKouBei;
    let res1 = await this.$datas.getClassInSchool;
    this.classes = res1.data.msg.data;
    this.koubeis = this.filterData(res.data);
    this.loading = false;
  },
  methods: {
    filterData(datas) {
      //按班级罗列
      let koubeis = [];
      for (let i in datas) {
        let stu = datas[i];
        let orExist = false;
        for (let j in koubeis) {
          if (stu.jieshao_class == koubeis[j].className) {
            orExist = true;
            koubeis[j].num += 1;
            koubeis[j].stus.unshift(stu);
            break;
          }
        }
        if (orExist == false && stu.jieshao_class) {
          let item = this.deal_class(stu.jieshao_class);
          if (item) {
            if (/^ZZ/.test(stu.jieshao_class)) {
              koubeis.push({
                className: stu.jieshao_class,
                num: 1,
                stus: [stu],
                ...item,
              });
            }
          }
        }
      }
      //按照口碑数降序排序
      koubeis.sort((item1, item2) => item2.num - item1.num);
      //获取半年内，一年内和一年外
      let date = new Date();
      let cn = this.getTimeStr(date); //当前时间
      date.setMonth(date.getMonth()-6)
      let bn = this.getTimeStr(date); //半年的时间
      date = new Date();
      date.setFullYear(date.getFullYear()-1);
      let yn = this.getTimeStr(date); //一年的时间
      let koubeisByTime = [
        {
            id:1,
            label:'在校班',
            biyeDate:'',
            className:'',
            jiuyeEndDate:'',
            kaibanDate:'',
            num:'',
            studentCount:'',
            stus:'',
            teacherList:'',
            zhigui:'',
            children:[]
         },
         {
            id:2,
            label:'毕业半年内',
            biyeDate:'',
            className:'',
            jiuyeEndDate:'',
            kaibanDate:'',
            num:'',
            studentCount:'',
            stus:'',
            teacherList:'',
            zhigui:'',
            children:[]
         },
         {
            id:3,
            label:"毕业一年内",
             biyeDate:'',
            className:'',
            jiuyeEndDate:'',
            kaibanDate:'',
            num:'',
            studentCount:'',
            stus:'',
            teacherList:'',
            zhigui:'',
            children:[]
         },
         {
            id:4,
            label:"毕业一年外",
            biyeDate:'',
            className:'',
            jiuyeEndDate:'',
            kaibanDate:'',
            num:'',
            studentCount:'',
            stus:'',
            teacherList:'',
            zhigui:'',
            children:[]
         }
      ]
      for(let i in koubeis){
         let item = koubeis[i];
         if(item.biyeDate > cn){
            koubeisByTime[0].children.push({...item, label:'在校班', id:"1"+i});
         }else if(item.biyeDate >= bn){
            koubeisByTime[1].children.push({...item, label:'毕业半年内', id:"2"+i});
         }else if(item.biyeDate >= yn && item.biyeDate < bn){
            koubeisByTime[2].children.push({...item, label:'毕业一年内', id:"3"+i});
         }else{
            koubeisByTime[3].children.push({...item, label:'毕业一年外', id:'4'+i});
         }
      }
      console.log('------------');
      console.log(koubeisByTime);
      return koubeisByTime;
    },
    deal_class(className) {
      let item = null;
      for (let i in this.classes) {
        if (this.classes[i].className == className) {
          item = this.classes[i];
          break;
        }
      }
      if (item) {
        let teas = [];
        for (let i in item.teacherList) {
          teas.push(item.teacherList[i].teacherName);
        }
        let set = new Set(teas);
        return { ...item, teacherList: [...set].join(" | ") };
      } else {
        return null;
      }
    },
    checkInfo(data) {
      this.$router.push({ name: "BJKBInfo", query: { row: data } });
    },
    getTimeStr(date){
        let year = date.getFullYear();
        let month = date.getMonth()+1;
        month = month < 10 ? "0"+month:month;
        let day = date.getDate();
        day = day < 10 ? "0"+day:day;
        return `${year}-${month}-${day}`;
    }
  },
};
</script>
