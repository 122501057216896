<template>
  <div class="yg_koubei_check">
    <div class="close">
      <i class="el-icon-close" @click="closeAction"></i>
    </div>
    <el-table
      :data="koubeis"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      border
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="tea" label="录入人" sortable width="180">
      </el-table-column>
      <el-table-column prop="time" label="录入时间" sortable width="180">
      </el-table-column>
      <el-table-column prop="name" label="口碑姓名" sortable width="180">
      </el-table-column>
      <el-table-column prop="state" label="口碑状态" sortable width="180">
      </el-table-column>
      <el-table-column prop="banji" label="口碑来源"> </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "BJKBInfo",
  data() {
    return {};
  },
  computed: {
    koubeis() {
      let fenzu = [];
      for (let i in this.stus) {
        let item = this.stus[i];
        let orExist = false;
        for (let j in fenzu) {
          let item1 = fenzu[j];
          if (item1.tea == item.lururen) {
            orExist = true;
            item1.children.push({
              id: item.id + "-" + item.studentName,
              name: item.studentName,
              tea: item1.tea,
              time: this.kind.val == 0 ? item.lurutime:item.payTime,
              state: item.kehuStatus,
              banji: item.jieshao_class,
            });
            break;
          }
        }
        if (orExist == false) {
          fenzu.push({
            id: item.id,
            tea: item.lururen,
            name: "",
            time: "",
            state: "",
            banji: "",
            children: [
              {
                id: item.id + "-" + item.studentName,
                tea: item.lururen,
                name: item.studentName,
                time: this.kind.val == 0 ? item.lurutime:item.payTime,
                state: item.kehuStatus,
                banji: item.jieshao_class,
              },
            ],
          });
        }
      }
      for(let i in fenzu){
        fenzu[i].children.sort((item1, item2)=>{
            if(item2.time > item1.time) {
                return 1;
            }
            return -1;
        });
      }
      return fenzu;
    },
  },
  props: ["stus", "kind"],
  methods: {
    closeAction() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="less" scoped>
.yg_koubei_check {
  padding: 5px 10px;
  .close {
    text-align: right;
    padding: 5px 30px;
    box-sizing: border-box;
    i {
      font-size: 20px;
      cursor: pointer;
      color: #555;
      &:hover {
        color: #aaff;
      }
    }
  }
}
</style>
