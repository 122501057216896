<template>
  <div class="koubei" v-loading="loading">
    <div class="first">
      <h3>口碑统计</h3>
      <div class="btns">
        <i class="el-icon-refresh" @click="refreshAction"></i>
        <i class="el-icon-circle-plus-outline" @click="addAction"></i>
      </div>
    </div>
    <div class="filter">
      <span class="demonstration">筛选</span>
      <el-date-picker
        v-model="year"
        type="year"
        placeholder="选择年">
      </el-date-picker>
    </div>
    <div class="links" v-if="!loading">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="班级口碑" name="first">
          <BJKouBei :year="year.getFullYear()"/>
        </el-tab-pane>
        <el-tab-pane label="员工口碑" name="second">
          <YGKouBei :stus="stus" :year="year.getFullYear()" v-if="load" />
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="content"></div>
  </div>
</template>
<script>
import BJKouBei from "./KouBei/BJKouBei.vue";
import YGKouBei from "./KouBei/YGKBInfo.vue";
export default {
  name: "KouBeiLive",
  data() {
    return {
      activeName: "first",
      stus: [],
      loading: false,
      load: false,
      year:new Date()
    };
  },
  watch:{
    async year(newV){
        newV = newV ? newV:(new Date());
        this.loading = true;
        this.$datas.meta = { kind: 1, date:newV.getFullYear()};
        this.year = newV;
        let res = await this.$datas.getKouBei;
        this.stus = res.data;
        this.loading = false;
    }
  },
  async created() {
    let date = new Date();
    this.$datas.meta = { kind: 1, date:date.getFullYear()};
    this.year = date;
    let res = await this.$datas.getKouBei;
    this.stus = res.data;
    this.load = true;
  },
  components: {
    BJKouBei,
    YGKouBei,
  },
  methods: {
    addAction() {
      this.$router.push({ name: "KouBeiAdd" });
    },
    async refreshAction() {
      this.loading = true;
      this.$datas.meta = { kind: 1};
      let res = await this.$datas.getKouBei;
      let stus = res.data;
      let tels = [];
      for (let i in stus) {
        let item = stus[i];
        if (item.kehuStatus != '已结清') {
          if (item.tel) {
            tels.push(item.tel);
          } else {
            tels.push(item.qq_wx);
          }
        }
      }
      let page =
        tels.length % 100 == 0
          ? tels.length / 100
          : Math.ceil(tels.length / 100);
      for (let i = 0; i < page; i++) {
         let tel_datas = tels.slice(i * 100, (i + 1) * 100);
         this.$datas.meta = { telOrLM: tel_datas.join(",") };
         let res = await this.$datas.getKouBeiState;
         res.data.msg.data.map(item=>{
            if(item.tel == "19137934393"){
              console.log(item);
            }
            return item;
         });
         for (let i in res.data.msg.data) {
           let item = res.data.msg.data[i];
           for (let j in stus) {
             //更新数据
             let item1 = stus[j];
             if (item1.tel) {
                if(item1.tel == item.tel){
                    this.$datas.meta = { id: item1.id, ...item };
                    await this.$datas.updateKouBei;
                    break;
                }
             } else if (item1.qq_wx) {
                if(item1.qq_wx == item.im){
                    this.$datas.meta = { id: item1.id, ...item };
                    await this.$datas.updateKouBei;
                    break;
                }
             }
           }
         }
      }
      this.loading = false;
    },
    handleClick(tab) {
      if (tab.index == "0") {
        this.activeName = "first";
      } else {
        this.activeName = "second";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.koubei {
  padding: 5px 10px;
  .filter {
    padding: 10px 0;
    span {
      font-size: 15px;
      color: #555;
      margin-right: 10px;
    }
  }
  .links {
    margin: 10px 0;
    a {
      margin: 0 10px;
    }
  }
  .content {
    margin: 10px 0;
  }
  .first {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btns {
      i {
        font-size: 20px;
        margin: 0 10px;
        cursor: pointer;
        color: #555;
        &:hover {
          color: #aaff;
        }
      }
    }
  }
  h3 {
    color: #555;
    font-size: 16px;
  }
}
</style>
